import { format, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";

export function getPeriodicity(type: string) {
  const Periodicity = {
    MONTHLY: "Mensal",
    SEMESTER: "Semestral",
    YEARLY: "Anual",
  };
  return Periodicity[type] || type;
}

export const getStatus = (status: string) => {
  const Status = {
    SCHEDULED: "Agendadas",
    CANCELED: "Canceladas",
    PAID: "Pago",
    PAID_MANUALLY: "Pago Manualmente",
    LATE: "Atrasado",
    ACTIVE: "Ativo",
    PENDING: "Pendente",
    AUTHORIZED: "Autorizado",
    FAILED: "Erro",
    INACTIVE: "Inativo",
    ABANDONED: "Desistência",
    DEFAULTER: "Inadimplente",
    CHARGEBACK: "Contestada",
    REFUND: "Estornada",
    DISPUTE: "Em disputa"
  };
  return Status[status] || status;
};

export const getNextLetter = (letter: string) => {
  if (typeof letter !== 'string' || letter.length !== 1 || !/[a-zA-Z]/.test(letter)) {
      throw new Error("Input must be a single alphabetical character.");
  }
  if (letter === 'z') {
      return 'a';
  } else if (letter === 'Z') {
      return 'A';
  } else {
      return String.fromCharCode(letter.charCodeAt(0) + 1);
  }
}
export const getColorBadgeStatus = (status: string) => {
  const BadgeStatus = {
    SCHEDULED: "yellow",
    CANCELED: "lightred",
    PAID: "green",
    PAID_MANUALLY: "green",
    LATE: "yellow",
    ACTIVE: "lightgreen",
    PENDING: "yellow",
    AUTHORIZED: "blue",
    FAILED: "red",
    INACTIVE: "gray",
    ABANDONED: "volcano",
    DEFAULTER: "volcano",
    PROCESS_CANCEL: "lightred",
    SEND_INVOICE_SYSTEM: "yellow",
    SEND_AUTHORIZED: "yellow",
    PROCESS_AUTHORIZED: "yellow",
    CHARGEBACK: "yellow",
    REFUND: "yellow",
    CHARGEBACK_LIST: "yellowDark",
    DISPUTE: "lightPink",
    BLOCKED: "lightred"
  };
  return BadgeStatus[status] || "gray";
};

export const getColorsBillsStatus = (status: string) => {
  const BillsStatus = {
    Aprovado: "blueBills",
    Pagamentoagendado: "blueBills",
    Aprovar: "yellowBills",
    Reprovado: "orangeBills",
    Atualizaçãonecessária: "yellowBills",
    Erronopaamento: "redBills",
    Saldoinsulficiente: "orangeBills",
    Paga: "greenBills",
    Cancelada: "orangeBills"
  }
  return BillsStatus[status] || "black"
}

export const getPaymentMethod = (type: string) => {
  const PaymentMethod = {
    CREDIT_CARD: "Cartão de Crédito",
    CREDIT_CARD_EARLY_SELLER: "Cartão de Crédito",
    CREDIT_CARD_EARLY_BUYER: "Cartão de Crédito",
    CREDIT_CARD_EARLY_MIXED: "CREDIT_CARD_EARLY_MIXED",
    BANK_SLIP: "Boleto",
    PIX: "Pix",
  };
  return PaymentMethod[type] || type;
};

export const getInvoiceStatus = (status: string) => {
  const Status = {
    AUTHORIZED: "Emitida",
    FAILED: "Erro",
    NOT_EMITTED: "Não Emitida",
    PROCESS_CANCEL: "Cancelamento em processo",
    CANCELED: "Cancelada",
    SEND_INVOICE_SYSTEM: "Emissão em processamento",
    SEND_AUTHORIZED: "Emissão em processamento",
    PROCESS_AUTHORIZED: "Emissão em processamento",
  };

  return Status[status] || "Não Emitida";
};

export const getOperationMethod = (type?: string) => {
  const OperationMethod = {
    DEBIT_TRANSFER: "Repasse",
    INSTALLMENTS_ANTICIPATION: "Parcela de antecipação",
    DEBIT_CORRECTION: "Correção",
    CREDIT_CORRECTION: "Correção",
    FEE_DISCOUNT: "Desconto em taxas",
    REVENUE_ANTICIPATION: "Antecipação de receita",
  };
  return OperationMethod[type] || type;
};

export const getInitials = (value: string) => {
  if (value === "") {
    return "-";
  }
  const initials = value
    .split(" ")
    .map((w) => w[0])
    .join("")
    .substring(0, 2);
  return initials;
};

export function capitalizeFirstLetter(str) {
  return str?.charAt(0)?.toUpperCase() + str?.slice(1);
}

export function formatWhiteLabelNames(str) {
  const whitelabels = {
    parcelamais: "Parcela mais",
    azp: "AZ Pague",
    flipsaude: "Quero flip",
    appbrlcard: "Brl",
    bsbpaycredit: "BSB Pay",
    bmhealthinvest: "BM Invest",
    stanleysveins: "Stanley's Veins"
  }
  return whitelabels[str] || capitalizeFirstLetter(str);
}

export function formatPixKeyPlaceholders(str) {
  const pixKeyPlaceholders = {
    EMAIL: "nome@empresa.com.br",
    PHONE: "11 99999 9999",
    DOCUMENT: "01.123.456/0001-99",
    ALLEATORY_KEY: "",
  }
  return pixKeyPlaceholders[str] || "Digite sua chave pix.";
}

export function formatPixKeyMasks(str) {
  const pixKeyMasks = {
    EMAIL: "",
    ALLEATORY_KEY: "",
    PHONE: "cellphone",
    DOCUMENT: "cnpj",
  }
  return pixKeyMasks[str] || "";
}

export function formatCep(value: string) {
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d{5})(\d)/, "$1-$2");
  return value;
}

export function formatFinalNumbers(value: string) {
    const ultimosQuatro = value?.slice(-4);
    return ultimosQuatro;
}

export function formatCreditCard(cardNumber: string) {
  if (cardNumber?.length !== 16) {
    return cardNumber;
  }
  return cardNumber?.replace(/(\d{4})/g, '$1 ').trim();
}

export function  formatDateAndHour(isoString) {
  const date = parseISO(isoString?.split("[")[0]);
  const formattedDate = format(date, "dd/MM/yy", { locale: ptBR });
  const formattedTime = format(date, "HH:mm:ss");
  return { formattedDate, formattedTime };
};

export function convertData(dataString: string) {
  const partes = dataString.split('/');
  if (partes.length === 3) {
      return `${partes[2]}-${partes[1]}-${partes[0]}`;
  }
  return null;
}

export function formatCardStatus(status: string) {
  const cardStatus = {
    ACTIVE: "Ativo",
    BLOCKED: "Bloqueado",
  }
  return cardStatus[status] || "";
}

export const formatKycAnalysisResult = (result: string): Record<string, string[]> => {
  if (!result) return {};

  try {
    const cleanResult = result.replace(/[{}]/g, '').trim();
    const match = cleanResult.match(/(.+?)=\[(.*?)\]/);
    if (!match) return {};
    const [, key, message] = match;
    return {
      [key]: [message]
    };
  } catch (error) {
    console.error('Erro ao formatar resultado:', error);
    return {};
  }
};

export function formatPhoneE164(phone: string): string {
  if (!phone) return '';
  const cleaned = phone.replace(/\D/g, '');
  if (phone.startsWith('+')) {
    return phone;
  }

  return `+${cleaned}`;
}

export function splitFullName(fullName: string): { firstName: string; lastName: string } {
  if (!fullName) return { firstName: '', lastName: '' };
  const [firstName, ...lastNameParts] = fullName.trim().split(' ');
  const lastName = lastNameParts.join(' ');
  return { firstName, lastName };
}
